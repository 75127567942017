import React from "react";
import { useNavigate } from "react-router-dom";

import './EpisodeCard.css';

function EpisodeCard(props) {
    let navigate = useNavigate();

    return (
        <div className="card-wrapper-column">
            <div className="episode-card">
                <a href={`/podcast/${props.name.toLowerCase().replace(/\./g, '').replace(/ /g, "-")}`} className="a-no-decoration">
                <div className="logo-wrapper">
                    <img src={`../../../../../podcast_images/company_logos/${props.company}.svg`} alt="Netflix logo" className="company-logo" />
                </div>
                <div className="headshot-and-title">
                    <div className="title">
                        <p>S{props.series_number} • E{props.episode_number}</p>
                        <h4>{props.name}</h4>
                        <h5>{props.job_title}</h5>
                    </div>
                    <div className="headshot-container">
                        <img src={`../../../../../podcast_images/headshots/${props.name.toLowerCase().replace(/\./g, '').replace(/ /g, "-")}-headshot-small.webp`} className="headshot-circle" alt="Headshot" />
                    </div>
                </div>
                <div className="description-and-cta">
                    <p>{props.description}</p>
                    <button onClick={() => navigate(`/podcast/${props.name.toLowerCase().replace(/\./g, '').replace(/ /g, "-")}`)} className="btn-link"><span className="btn-label">Listen to the episode</span></button>
                </div>
                </a>
            </div>
        </div>
    )
}

export default EpisodeCard;