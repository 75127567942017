import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import "./ComputingLegends.css";
import NewsletterFooter from "../components/universal/NewsletterFooter/NewsletterFooter";

function ComputingLegends() {
    let legends = [
        {month:"January", name:"Grace Hopper", sentence:"Born in 1906, she pioneered the development of high-level programming languages and programmed one of the first electromechanical computers during WWII. She created the first compiler and even coined the terms \"computer bug\" and \"debugging\"."},
        {month:"February", name:"Sir Tim Berners-Lee", sentence:"The ground-breaking work of Berners-Lee at CERN in 1989, to create an \"information management system\", is what originally led to the World Wide Web being created. He created foundational technologies like HTML, HTTP and URLs, which power the web to this day."},
        {month:"March", name:"Annie Easley", sentence:"A computer scientist, mathematician and rocket scientist, Easley's work at NASA propelled ground-breaking projects such as the Centaur project. She also spearheaded initiatives for equality as NASA's equal employement opportunities counsellor and helped many students, inspiring them to consider a career in STEM."},
        {month:"April", name:"Ada Lovelace and Charles Babbage", sentence:"The remarkable partnership between Ada Lovelace and Charles Babbage led to the creation of the first ever computer program: an algorithm to calculate Bernoulli numbers on the Analytical Engine."},
        {month:"May", name:"John von Neumann", sentence:"John von Neumann, a mathematical genius and polymath, pioneered game theory and is best known among students for the 'von Neumann architecture'. The foundation of most modern computers, this is where memory stores both the program instructions and program data."},
        {month:"June", name:"Alan Turing", sentence:"Turing is renowned for his crucial role in cracking the Enigma code during World War II, an achievement that significantly shortened the war and saved millions of lives. Not only this, but he was also a pioneer in artificial intelligence, having proposed the influential Turing Test."},
        {month:"July", name:"Kathleen Booth", sentence:"Kathleen Booth was a pioneer in computer development. Among many other achievements, she is credited with the invention of the first assembly language, wrote one of the first books on programming and helped create an algorithm fundamental to billions of computer processors today."},
        {month:"August", name:"Dina St Johnston", sentence:"St Johnston contributed to early computer development, including the Elliott 153 during the Cold War. Noticing a gap in the market, she founded Vaughan Programming Services in 1959 - the UK's first independent software house."},
        {month:"September", name:"Edsger W. Dijkstra", sentence:"Edsger W. Dijkstra's contributions to computer science ranged from engineering to theoretical. While he was likely most well known by students for his shortest name algorithm, he also pioneered the field of concurrent programming and developed the first compiler for ALGOL 60, a language that brought huge advances in structured programming."},
        {month:"October", name:"Gladys West", sentence:"Gladys West was the second ever women of colour to be hired at the naval base in Dahlgren, Virginia. There she refined calculations for satellite orbits, which were essential for creating a precise model of Earth's shape. This model is what underpins GPS technology today."},
        {month:"November", name:"Elizabeth Feinler", sentence:"Feinler was a pioneering information scientist, who played a crucial role in the development of the ARPANET, and laid the foundation for many of the online services we take for granted today."}
    ]

    return (
        <div>
			<Helmet>
				<title>Computing Legends poster series from Mission Encodeable</title>
				<meta name="description" content="Mission Encodeable's free 'computing legends' poster series celebrates the key pioneers in computing." />
			</Helmet>

            <div className="computing-legends-header">
                <h1>Computing <em>Legends</em> poster series</h1>
                <p>Each month in 2024 we'll be releasing a new downloadable A3 poster and accompanying blog post to celebrate some of the key pioneers in computing history. Teachers, make sure you sign up to our newsletter to get the latest posters directly in your inbox!</p>
            </div>

            {legends.map((legend) =>
                <div className="computing-legend">
                    <div className="computing-legend-image">
                        <img alt={legend.name} src={`../../../../../blog_images/blog-post-computing-legends-${legend.name.toLowerCase().replace(/\./g, '').replace(/ /g, "-")}-feature-image.webp`}/>
                    </div>
                    <div className="computing-legend-content">
                        <h3>{legend.month}: {legend.name}</h3>
                        <p>{legend.sentence}</p>
                        <a href={`https://rebrand.ly/ME-${legend.name.toLowerCase().replace(/\./g, '').replace(/ /g, "-")}-poster`} target="_blank" rel="noreferrer"><button className="btn_orange_fill">Download poster</button></a>
                        <Link to={`/blog/computing-legends-${legend.name.toLowerCase().replace(/\./g, '').replace(/ /g, "-")}`} className="a-no-decoration"><button className="btn_orange_fill">Read blog post</button></Link>
                    </div>
                </div>
            )}

            {/* <div className="computing-legend">
                <div className="computing-legend-image">
                    <img alt="Grace Hopper" src="../../../../../blog_images/blog-post-computing-legends-grace-hopper-feature-image.webp"/>
                </div>
                <div className="computing-legend-content">
                    <h3>January: Grace Hopper</h3>
                    <p>Born in 1906, she pioneered the development of high-level programming languages and programmed one of the first electromechanical computers during WWII. She created the first compiler and even coined the terms “computer bug” and “debugging”.</p>
                    <a href="https://rebrand.ly/ME-grace-hopper-poster" target="_blank" rel="noreferrer"><button className="btn_orange_fill">Download poster</button></a>
                    <Link to="/blog/computing-legends-grace-hopper" className="a-no-decoration"><button className="btn_orange_fill">Read blog post</button></Link>
                </div>
            </div>

            <div className="computing-legend">
                <div className="computing-legend-image">
                    <img alt="Sir Tim Berners-Lee" src="../../../../../blog_images/blog-post-computing-legends-sir-tim-berners-lee-feature-image.webp"/>
                </div>

                <div className="computing-legend-content">
                    <h3>February: Sir Tim Berners-Lee</h3>
                    <p>The ground-breaking work of Berners-Lee at CERN in 1989, to create an “information management system”, is what originally led to the World Wide Web being created. He created foundational technologies like HTML, TCP, and DNS, which power the web to this day.</p>
                    <a href="https://rebrand.ly/ME-Sir-Tim-Berners-Lee-poster" target="_blank" rel="noreferrer"><button className="btn_orange_fill">Download poster</button></a>
                    <Link to="/blog/computing-legends-sir-tim-berners-lee" className="a-no-decoration"><button className="btn_orange_fill">Read blog post</button></Link>
                </div>
            </div>

            <div className="computing-legend">
                <div className="computing-legend-image">
                    <img alt="Annie Easley" src="../../../../../blog_images/blog-post-computing-legends-annie-easley-feature-image.webp"/>
                </div>
                <div className="computing-legend-content">
                    <h3>March: Annie Easley</h3>
                    <p>A computer scientist, mathematician and rocket scientist, Easley's work at NASA propelled ground-breaking projects such as the Centaur project. She also spearheaded initiatives for equality as NASA's equal employement opportunities counsellor and helped many students, inspiring them to consider a career in STEM.</p>
                    <a href="https://rebrand.ly/ME-annie-easley-poster" target="_blank" rel="noreferrer"><button className="btn_orange_fill">Download poster</button></a>
                    <Link to="/blog/computing-legends-annie-easley" className="a-no-decoration"><button className="btn_orange_fill">Read blog post</button></Link>
                </div>
            </div>

            <div className="computing-legend">
                <div className="computing-legend-image">
                    <img alt="Ada Lovelace and Charles Babbage" src="../../../../../blog_images/blog-post-computing-legends-ada-lovelace-and-charles-babbage-feature-image.webp"/>
                </div>
                <div className="computing-legend-content">
                    <h3>April: Ada Lovelace and Charles Babbage</h3>
                    <p>The remarkable partnership between Ada Lovelace and Charles Babbage led to the creation of the first ever computer program: an algorithm to calculate Bernoulli numbers on the Analytical Engine.</p>
                    <a href="https://rebrand.ly/ME-annie-easley-poster" target="_blank" rel="noreferrer"><button className="btn_orange_fill">Download poster</button></a>
                    <Link to="/blog/computing-legends-ada-lovelace-and-charles-babbage" className="a-no-decoration"><button className="btn_orange_fill">Read blog post</button></Link>
                </div>
            </div> */}
            
            <NewsletterFooter />
    	</div>
    );
};

export default ComputingLegends;