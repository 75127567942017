import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import "./PodcastEpisode.css"

import hero_image from "../images/pages/podcast/ITC_hero_logo_case.svg";
import apple_podcasts from "../images/pages/podcast/Apple Podcasts.svg";
import spotify from "../images/pages/podcast/Spotify.svg";
import rss from "../images/pages/podcast/RSS.svg";

import GetPodcastEpisode from "../hooks/getPodcastEpisode.js";
import PodcastCardSwiper from "../components/pages/podcast/PodcastCardSwiper.js";

const parse = require('html-react-parser');
const DOMPurify = require('dompurify');

function Podcast() {
    let params = useParams();
    let episode_name = params.name;

    let { series_number, episode_number, name, job_title, company, description, duration, release_date, spotify_episode_id, apple_episode_id, rss_episode_id } = GetPodcastEpisode(episode_name);

    let clean_content = DOMPurify.sanitize(description)
    return (
        <div className="podcast">

            <Helmet>
                <title>Inside Tech Careers</title>
                <meta name="description" content="An episode of the Inside Tech Careers podcast." />
            </Helmet>


            <section className="podcast-episode-hero">
                <div className="text">
                    <h1 className="darkgreen">{name}</h1>
                    <h2 className="green">{job_title} at {company}</h2>
                </div>
                <div className="hero-image-wrapper">
                    <img className="hero-img" src={hero_image} alt="Inside Tech Careers, a project by Mission Encodeable" />
                </div>
            </section>

            <section className="podcast-player-and-description">
                <div className="podcast-player">
                    <iframe title="podcast-player" src={`https://embed.acast.com/6716a1b630187dfb6c307211/${rss_episode_id}?bgColor=00525e&secondaryColor=fe7c1b&font-family=Public%20Sans&font-src=https%3A%2F%2Ffonts.googleapis.com%2Fcss%3Ffamily%3DPublic%2BSans&subscribe=false`} frameBorder="0" width="100%" height="250"></iframe>
                </div>
                <div className="podcast-platforms">
                    <a href={`https://podcasts.apple.com/gb/podcast/id${apple_episode_id}`} target="_blank" rel="noreferrer"><img src={apple_podcasts} alt="Apple Podcasts" className="platform-logo" /></a>
                    <a href={`https://open.spotify.com/episode/${spotify_episode_id}`} target="_blank" rel="noreferrer"><img src={spotify} alt="Spotify" className="platform-logo" /></a>
                    <a href={`https://shows.acast.com/6716a1b630187dfb6c307211/episodes/${rss_episode_id}`} target="_blank" rel="noreferrer"><img src={rss} alt="RSS" className="platform-logo" /></a>
                </div>
                <div className="podcast-description">
                    <div className="description-sidebar">
                        <div className="description-sidebar-row">
                            <p className="field-name">Episode:</p>
                            <p className="field-data">{String(series_number).padStart(2, '0')} • {String(episode_number).padStart(2, '0')}</p>
                        </div>
                        <div className="description-sidebar-row">
                            <p className="field-name">Released:</p>
                            <p className="field-data">{release_date}</p>
                        </div>
                        <div className="description-sidebar-row">
                            <p className="field-name">Duration:</p>
                            <p className="field-data">{duration}</p>
                        </div>
                    </div>
                    <div className="description-content">
                        {parse(`${clean_content}`)}
                    </div>
                </div>
            </section>


            <section className="podcast-latest-episodes">
                <p className="mini-section-heading mini-section-heading-centred">MORE EPISODES</p>
                <h3 className="darkgreen">Listen to these <u>episodes</u> next</h3>

                <PodcastCardSwiper />

            </section>
        </div>

    );
}

export default Podcast;