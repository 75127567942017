import React from 'react'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { hotjar } from 'react-hotjar';

import Home from "./pages/Home.js"
import GetStarted from "./pages/GetStarted.js"
import FinishLegacy from "./pages/FinishLegacy.js"
import Blog from "./pages/Blog.js"
import About from "./pages/About.js"
import Contact from "./pages/Contact.js"
import RequestADemo from "./pages/RequestADemo.js"
import Privacy from "./pages/Privacy.js"
import Error from "./pages/Error.js"
import Course from './pages/Course.js'
import Introduction from './pages/Introduction.js'
import Finish from './pages/Finish.js'
import BlogPost from './pages/BlogPost.js';
import Cnd from './pages/Cnd.js';
import ComputingLegends from './pages/ComputingLegends.js';
import Podcast from './pages/Podcast.js';
import PodcastEpisode from './pages/PodcastEpisode.js';

import Navbar from './components/universal/Navbar/Navbar.js';
import Footer from './components/universal/Footer/Footer.js';
import ScrollToTop from './ScrollToTop.js';
import GoogleAnalytics from "./GoogleAnalytics.js";
import Teachers from './pages/Teachers.js';
import ChristmasHome from './pages/ChristmasHome.js';
import SummerProjectPage from './pages/SummerProjectPage.js';
import ChristmasProjectPage from './pages/ChristmasProjectPage.js';

import SummerHome from './pages/SummerHome.js';

function App(){
   /* Add HotJar */
   hotjar.initialize(2856964, 6);

   return(
       <div>
           <BrowserRouter>
               <ScrollToTop/>
               <GoogleAnalytics/>
               <div className="page-container">
                   <main>
                   <Navbar/>

                       <Routes>
                           <Route path="/" element={ <Home/> } />
                           <Route path="/get-started" element={ <GetStarted legacy={false} /> } />
                           <Route path="/get-started-legacy" element={ <GetStarted legacy={true} /> } />
                           <Route path="/blog" element={ <Blog/> } />
                           <Route path="/about" element={ <About/> } />
                           <Route path="/contact" element={ <Contact/> } />
                           <Route path="/request-a-demo" element={ <RequestADemo/> } />
                           <Route path="/privacy" element={ <Privacy/> } />
                           <Route path="/blog/:post_slug" element={ <BlogPost /> } />
                           <Route path="/teachers" element={ <Teachers /> } />
                           <Route path="/craig-n-dave-partnership" element={ <Cnd /> } />
                           <Route path="/legends" element={ <ComputingLegends /> } />
                           <Route path="/podcast" element={ <Podcast /> } />
                           <Route path="/podcast/:name" element={ <PodcastEpisode /> } />
                           <Route path='/:course/level/:levelNumber' element={<Introduction />} />
                           <Route path="/:course/level/:levelNumber/step/:stepNumber" element={<Course />} />
                           <Route path="/python/level/:levelNumber/finish" element={<Finish />} />
                           <Route path="/python-legacy/level/:levelNumber/finish" element={<FinishLegacy />} />
                           <Route path="/christmas" element={ <ChristmasHome /> } />
                           <Route path="/christmas/python/level/:levelNumber" element={<ChristmasProjectPage />} />
                           <Route path="/summer" element={ <SummerHome /> } />
                           <Route path="/summer/python/level/:levelNumber" element={<SummerProjectPage />} />
                           <Route path="*" element={<Error/>}/>
                       </Routes>
                   </main>
                   <Footer/>
               </div>
           </BrowserRouter>
       </div>
   )
}


export default App;
