import {useEffect, useState} from "react";

function GetPodcastEpisode(episode_name) {
    const [series_number, setSeriesNumber] = useState([]);
    const [episode_number, setEpisodeNumber] = useState([]);
    const [name, setName] = useState([]);
    const [job_title, setJobTitle] = useState([]);
    const [company, setCompany] = useState([]);
    const [description, setDescription] = useState([]);
    const [duration, setDuration] = useState([]);
    const [release_date, setReleaseDate] = useState([]);
    const [spotify_episode_id, setSpotifyEpisodeId] = useState([]);
    const [apple_episode_id, setAppleEpisodeId] = useState([]);
    const [rss_episode_id, setRSSEpisodeId] = useState([]);

    useEffect(() => {
        const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
        const url = `${host}/api/podcasts/${episode_name}`;
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();

                setSeriesNumber(json[0].series_number);
                setEpisodeNumber(json[0].episode_number);
                setName(json[0].name);
                setJobTitle(json[0].job_title);
                setCompany(json[0].company);
                setDescription(json[0].full_description);
                setDuration(json[0].duration);
                setReleaseDate(json[0].release_date);
                setSpotifyEpisodeId(json[0].spotify_episode_id);
                setAppleEpisodeId(json[0].apple_episode_id);
                setRSSEpisodeId(json[0].rss_episode_id);
                
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    })

    return { series_number, episode_number, name, job_title, company, description, duration, release_date, spotify_episode_id, apple_episode_id, rss_episode_id };

}
export default GetPodcastEpisode;