import React from 'react';
import "./Error.css";

import loading_spinner from "../images/universal/loading_spinner.svg";

function Loading() {
    return (
        <div className="Error_box">
            <img src={loading_spinner} className="loading-spinner" alt="Loading spinner"/>
        </div>
    )
}

export default Loading;