import React from "react";
import { Helmet } from "react-helmet";

import "./Podcast.css"

import hero_image from "../images/pages/podcast/podcast_hero_image.svg";
import apple_podcasts from "../images/pages/podcast/Apple Podcasts.svg";
import spotify from "../images/pages/podcast/Spotify.svg";
import rss from "../images/pages/podcast/RSS.svg";
import PodcastCardSwiper from "../components/pages/podcast/PodcastCardSwiper.js";

// import david_leen_headshot from "../../public/podcast_images/headshots/david-leen-headshot-small.webp";
// import netflix_logo from "../../public/podcast_images/company_logos/Netflix.svg";

function Podcast() {
    return (
        <div className="podcast">

            <Helmet>
                <title>Inside Tech Careers Podcast from Mission Encodeable</title>
                <meta name="description" content="Listen to the Inside Tech Careers podcast to get a look behind the scenes in tech. You can expect to hear from inspiring individuals ranging from designers to data scientists to developers!" />
            </Helmet>


            <section className="podcast-hero">
                <div className="text">
                    <h1 className="darkgreen">Hear what it's <em>really</em> like to work in tech</h1>
                    <p className="darkgreen">With new episodes of the Inside Tech Careers podcast released each week, you can expect to hear from inspiring individuals ranging from designers to data scientists to developers.</p>
                    <div className="podcast-platforms">
                        <a href="https://podcasts.apple.com/us/podcast/inside-tech-careers/id1775260741" target="_blank" rel="noreferrer"><img src={apple_podcasts} alt="Apple Podcasts" className="platform-logo" /></a>
                        <a href="https://open.spotify.com/show/2Hr0frzQurnocuUucl7HX7" target="_blank" rel="noreferrer"><img src={spotify} alt="Spotify" className="platform-logo" /></a>
                        <a href="https://feeds.acast.com/public/shows/6716a1b630187dfb6c307211" target="_blank" rel="noreferrer"><img src={rss} alt="RSS" className="platform-logo" /></a>
                    </div>
                </div>
                <div className="hero-image-wrapper">
                    <img className="hero-img" src={hero_image} alt="Inside Tech Careers, a project by Mission Encodeable" />
                </div>
            </section>

            <section className="podcast-latest-episodes">
                <p className="mini-section-heading mini-section-heading-centred">Series 1</p>
                <h3 className="darkgreen">Listen to the <u>interviews</u></h3>

                <PodcastCardSwiper />
            </section>
        </div>

    );
}

export default Podcast;