import React from 'react';
import { useLocation } from "react-router-dom";

import GetAllPodcastEpisodes from '../../../hooks/getAllPodcastEpisodes';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import SwiperCore, { Navigation } from 'swiper';

import '../resources/BlogCardSwiper/BlogCardSwiper.css';
import EpisodeCard from './EpisodeCard';

SwiperCore.use([Navigation]);

function PodcastCardSwiper() {

    const { state } = useLocation();
    let { all_episodes } = GetAllPodcastEpisodes(state); 

    return (
        <div className="blog-card-swiper-container">
            <Swiper
                slidesPerView={1}
                navigation
                spaceBetween={10}
                pagination={{
                clickable: true,
                renderBullet: function(index, className) {
                    return '<span class="' + className + '"><img class="pagination-bullet" alt=""/></span>';
                }
                }}
                breakpoints={{
                    590: {
                        slidesPerView: 1.4,
                        spaceBetween: 10,
                    },
                    675: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    875: {
                        slidesPerView: 2.4,
                        spaceBetween: 20,
                    },
                    1040: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1230: {
                        slidesPerView: 3.4,
                        spaceBetween: 20,
                    },
                    1900: {
                        slidesPerView: 4.4,
                        spaceBetween: 20,
                    },
                }}
                modules={[Pagination]}
                centerInsufficientSlides={true}
                className="podcastCardSwiper"
            >
                {all_episodes.map((episode, index) => 
                    <SwiperSlide key={index}>
                        <EpisodeCard
                            series_number={episode.series_number}
                            episode_number={episode.episode_number}
                            name={episode.name}
                            job_title={episode.job_title}
                            company={episode.company}
                            description={episode.teaser_line}
                        />
                    </SwiperSlide>
                )}
              </Swiper>
        </div>  
    )
}

export default PodcastCardSwiper;