import { useEffect, useState } from 'react';

function GetData(loaded_data) {

    const [all_episodes, setAllEpisodes] = useState([]);

    useEffect(() => {
        if ( loaded_data === null  ) {
            const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
            const url = `${host}/api/podcasts`;

            const fetchData = async () => {
                try {
                    
                    const response = await fetch(url);
                    const api_data = await response.json();

                    let episodes = []
                    for (let i = 0; i < api_data.length; i++){
                        episodes.push({
                            'series_number' : api_data[i].series_number, 
                            'episode_number' : api_data[i].episode_number, 
                            'name' : api_data[i].name, 
                            'job_title' : api_data[i].job_title,
                            'company' : api_data[i].company,
                            'teaser_line' : api_data[i].teaser_line
                        })
                    }

                    setAllEpisodes(episodes);

                } catch (error) {
                    console.log("error", error);
                }

            };
    
            fetchData();
        } else {
            setAllEpisodes(loaded_data.episodes)
        }

    }, [loaded_data]);
    return { all_episodes };
}

export default GetData;